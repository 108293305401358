import React from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import MyPage from '../components/my_page'

import MyImagesSingle from '../components/my_images_single'

const DraugaiPage = () => {
  const pageTitle = 'Draugai'
  const pageSeoTitle =
    'MŪSŲ PĖDUTĖS – privatus vaikų darželis bendradarbiaujantis su ' +
    'Sodoturtai.lt, darželiais Baltų šalelė bei Bebenčiukų namučiai, ' +
    'Darželių asociacija, Vilniaus miesto savivaldybe ' +
    'žurnalu Vaiko diena, Naujasis teatras'
  const pageCards = [
    {
      title: <a href="http://www.sodoturtai.lt">Sodoturtai.lt</a>,
      preBody: (
        <MyImagesSingle
          imgPublicId="musupedutes/draugai/logo_sodoturtai"
          imgContain
        />
      ),
      body: ''
    },
    {
      title: <a href="http://www.baltusalele.lt">Baltų šalelė</a>,
      preBody: (
        <MyImagesSingle
          imgPublicId="musupedutes/draugai/logo_darzelis_baltu_salele"
          imgContain
        />
      ),
      body: ''
    },
    {
      title: <a href="https://www.vilnius.lt">Vilniaus miesto savivaldybė</a>,
      preBody: (
        <MyImagesSingle
          imgPublicId="musupedutes/draugai/logo_vilniaus_savivaldybe"
          imgContain
        />
      ),
      body: ''
    },
    {
      title: <a href="https://vaikodiena.lt">Vaiko diena</a>,
      preBody: (
        <MyImagesSingle
          imgPublicId="musupedutes/draugai/logo_vaikodiena"
          imgContain
        />
      ),
      body: ''
    },
    {
      title: (
        <a href="http://www.bebenciukai.lt">Darželis "Bebenčiukų" namučiai</a>
      ),
      preBody: (
        <MyImagesSingle
          imgPublicId="musupedutes/draugai/logo_darzelis_bebenciuku_namuciai"
          imgContain
        />
      ),
      body: ''
    },
    {
      title: <a href="http://naujasisteatras.lt">Naujasis teatras</a>,
      preBody: (
        <MyImagesSingle
          imgPublicId="musupedutes/draugai/logo_naujasis_teatras"
          imgContain
        />
      ),
      body: ''
    },
    {
      title: (
        <a href="http://www.darzeliuasociacija.lt/">Darželių asociacija</a>
      ),
      preBody: (
        <MyImagesSingle
          imgPublicId="musupedutes/draugai/logo_NIUIA"
          imgContain
        />
      ),
      body: ''
    }
  ]

  return (
    <MyPage
      pageType="cardDeck"
      pageTitle={pageTitle}
      pageSeoTitle={pageSeoTitle}
      pageCards={pageCards}
    />
  )
}

export default DraugaiPage
